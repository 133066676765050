import React, { useState, useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { CiBellOn, CiCircleQuestion } from "react-icons/ci";
import { useFormik } from "formik";
import * as yup from "yup";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useAppDispatch } from "../../store/hooks";
import { setToken } from "../../store/slices/planSlice";
import AuthImage from "../../assets/images/backgrounds/AuthImage.png";
import { Link } from "react-router-dom";
import { appAxios } from "../../api/axios";
import { RoutePaths } from "../../routes";
import { isTokenValid } from "../../util";
import Tooltip from "../Tooltip";
import Cookies from "js-cookie";

const Login = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const dispatch = useAppDispatch();
	let navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: yup.object({
			email: yup
				.string()
				.email("Invalid email address!")
				.required("Email required!"),
			password: yup.string().required("Incorrect password!"),
		}),
		onSubmit: async (values) => {
			// Handle form submission
			setLoading(true);
			let payload = {
				email: values.email,
				password: values.password,
			};
			try {
				const response = await appAxios.post("/v1/auth/login", payload);
				if (
					response.data.statusCode === 200 &&
					response.data.success === true
				) {
					const { accessToken } = response.data.data;

					// If "Remember Me" is checked, set the cookie for 30 days
					if (rememberMe) {
						Cookies.set("accessToken", accessToken, { expires: 30 });
					} else {
						Cookies.set("accessToken", accessToken);
					}
					dispatch(setToken(response?.data?.data?.accessToken));
					navigate(RoutePaths.TRANSACTIONS);
					message.success(response?.data?.message);
					setLoading(false);
				} else if (response?.data?.statusCode === 401) {
					setErrorMessage(response?.data?.message);
					setLoading(false);
				} else {
					setErrorMessage(response?.data?.message);
					setLoading(false);
				}
			} catch (error) {
				console.error(
					"Form submission error:",
					error?.response?.data?.message?.message
				);
				setErrorMessage(error?.response?.data?.message?.message);

				setLoading(false);
			}
		},
	});

	const handlePasswordVisibilityToggle = () => {
		setShowPassword(!showPassword);
	};

	const handleRememberMeCheckbox = () => {
		setRememberMe(!rememberMe);
	};

	// Check token expiration every 5 seconds
	useEffect(() => {
		const interval = setInterval(() => {
			const token = Cookies.get("accessToken");
			if (!token) {
				navigate("/");
			}
		}, 5000);

		return () => clearInterval(interval);
	}, [navigate]);

	return (
		<AuthLayout
			title="Login to your account"
			description="Welcome back! Ready to streamline your process? Log in and let PAIBI handle the heavy lifting."
			backgroundImage={AuthImage}
			backgroundText="Say goodbye to stacks of paper."
			additionalInfo={
				<div className="flex flex-col">
					<Link to="/reset-password" className=" ">
						Forgot your password?{" "}
						<span className="text-[#9cabd7] hover:underline">
							Reset Password
						</span>
					</Link>
					<Link to="/onboarding" className="">
						Need an Account?{" "}
						<span className="text-[#9cabd7] hover:underline">Sign up</span>
					</Link>
				</div>
			}
			// buzzSales={
			// 	<button
			// 		type="button"
			// 		className="text-gray-900 mt-5 flex flex-row items-center gap-2 bg-white border border-slate-500 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2"
			// 	>
			// 		<CiBellOn /> Buzz the sales team
			// 	</button>
			// }
		>
			<form onSubmit={formik.handleSubmit} className="space-y-5 text-xs">
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Email{" "}
							<Tooltip text="Enter the email address associated with your account.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.email && formik.errors.email ? (
							<p className="text-[#D61B1B] text-[10px]">
								{formik.errors.email}
							</p>
						) : null}
						{/* <p className="text-[#D61B1B]">Incorrect Email!</p> */}
					</label>
					<input
						type="email"
						name="email"
						placeholder="john@acmeco.com"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.email}
						className="w-full px-3 py-2 bg-[#ffffff] text-gray-500  outline-none border focus:border-gray-600 shadow-sm rounded-lg"
					/>
				</div>
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Password{" "}
							<Tooltip text="Enter your account password. Minimum of 8 characters.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.password && formik.errors.password ? (
							<p className="text-[#D61B1B] text-[10px]">
								{formik.errors.password}
							</p>
						) : null}
					</label>
					<div className="relative">
						<input
							type={showPassword ? "text" : "password"}
							name="password"
							placeholder="**************"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.password}
							className="w-full px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
						/>
						<span
							onClick={handlePasswordVisibilityToggle}
							className="absolute mt-1 right-2 top-1/2 -translate-y-1/2 cursor-pointer"
						>
							{showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
						</span>
					</div>
					{errorMessage && (
						<div className=" space-y-2 flex gap-2 items-center justify-start my-[0px] ">
							<p className="text-[#D61B1B] text-[10px] my-[0px] text-end">
								{errorMessage}
							</p>
						</div>
					)}
				</div>
				<div className="flex items-center gap-2">
					<input
						type="checkbox"
						checked={rememberMe}
						onChange={handleRememberMeCheckbox}
						className="h-4 w-4"
					/>
					<span className="text-xs">Remember Me</span>
				</div>
				<button
					type="submit"
					className="w-full mt-5 px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
				>
					Login
				</button>
			</form>
		</AuthLayout>
	);
};

export default Login;
