import React, { useState } from "react";

const Tooltip = ({ text, children, position = "right" }) => {
	const [visible, setVisible] = useState(false);

	// Determine the tooltip's position classes
	const getPositionClasses = () => {
		switch (position) {
			case "top":
				return "bottom-full left-1/2 transform -translate-x-1/2 mb-2";
			case "bottom":
				return "top-full left-1/2 transform -translate-x-1/2 mt-2";
			case "left":
				return "right-full top-1/2 transform -translate-y-1/2 mr-2";
			case "right":
				return "left-full top-1/2 transform -translate-y-1/2 ml-2";
			default:
				return "";
		}
	};

	return (
		<div
			className="relative flex items-center"
			onMouseEnter={() => setVisible(true)}
			onMouseLeave={() => setVisible(false)}
		>
			{children}

			{visible && (
				<div
					className={`absolute w-auto z-10 md:w-52 lg:w-52 py-1 px-3 text-white bg-gray-700 rounded shadow-lg ${getPositionClasses()}`}
					// style={{
					// 	whiteSpace: "normal",
					// 	wordWrap: "break-word",
					// }}
					// className={`absolute z-10 w-max py-1 px-3 text-white bg-gray-700 rounded shadow-lg ${getPositionClasses()}`}
				>
					{text}
					<div
						className={`absolute w-3 h-3 bg-gray-700 transform rotate-45 ${
							{
								top: "-bottom-1 left-1/2 -translate-x-1/2",
								bottom: "top-0 left-1/2 -translate-x-1/2",
								left: "right-0 top-1/2 -translate-y-1/2",
								right: "-left-1 top-1/2 -translate-y-1/2",
							}[position]
						}`}
					/>
				</div>
			)}
		</div>
	);
};

export default Tooltip;
