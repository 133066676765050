import React, { useState } from "react";
import MainLayout from "../../../layouts/dashboardLayout/mainLayout";
import TransactionCard from "../../../components/dashboard/TransactionCard";
import { useDropzone } from "react-dropzone";
import { FaTrashAlt } from "react-icons/fa";

const Transactions = () => {
	const transactions = [
		{
			title: "Payment Received",
			date: "2024-11-08",
			time: "10:30 AM",
			description: "Received payment",
			id: "TRX123456",
			image: "/bundle_box.png",
			status: "Completed",
		},
		{
			title: "Invoice Sent",
			date: "2024-11-07",
			time: "4:15 PM",
			description: "Invoice sent for project",
			id: "TRX789012",
			image: "/bundle_box.png",
			status: "Pending",
		},
		{
			title: "Invoice Sent",
			date: "2024-11-07",
			time: "4:15 PM",
			description: "Invoice sent for project",
			id: "TRX789012",
			image: "/bundle_box.png",
			status: "Pending",
		},
	];

	const [sortBy, setSortBy] = useState("Newest");
	const [isUploading, setIsUploading] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [uploadProgress, setUploadProgress] = useState(0);
	const [uploadedFileName, setUploadedFileName] = useState("");

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles),
		multiple: false,
		accept: ".pdf .png",
	});

	const handleFileUpload = (files) => {
		setIsUploading(true);
		// Save the uploaded file name
		setUploadedFileName(files[0].name);
		let progress = 0;

		const interval = setInterval(() => {
			if (progress < 100) {
				progress += 10;
				setUploadProgress(progress);
			} else {
				clearInterval(interval);
				setIsUploading(false);
				console.log("Uploaded files:", files);
			}
			// Simulate upload progress every 500ms
		}, 500);
	};

	const handleRemoveFile = () => {
		setUploadedFileName("");
		setUploadProgress(0);
	};

	return (
		<MainLayout
			leftContent={
				<div>
					<h2 className="text-xl font-semibold mb-4">Transactions</h2>
					<p>
						Hello, John! There have been{" "}
						<span className="text-red-600">297</span> transactions processed
						since your last login.
					</p>
					{/* Search input */}
					<div className="mb-4">
						<input
							type="text"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							placeholder="Search Transaction..."
							className="w-full p-3 border-2 border-gray-400 mt-10 rounded-2xl"
						/>
					</div>

					{/* Sortby Newest and Oldest */}
					<div>
						<h3 className="font-bold mb-4">SortedBy</h3>
						<div className="flex flex-col justify-start gap-3 mb-10">
							<div
								className="flex justify-between items-center cursor-pointer"
								onClick={() => setSortBy("Newest")}
							>
								<label
									className={` ${sortBy === "Newest" ? "text-red-600" : ""}`}
								>
									Newest
								</label>
								<div
									className={`ml-2 h-3 w-3 rounded-full ${
										sortBy === "Newest"
											? "bg-red-600 border-2 h-4 w-4 border-white"
											: "bg-gray-400"
									}`}
								/>
							</div>
							<div
								className="flex justify-between items-center cursor-pointer"
								onClick={() => setSortBy("Oldest")}
							>
								<label
									className={`${sortBy === "Oldest" ? "text-red-600" : ""}`}
								>
									Oldest
								</label>
								<div
									className={`ml-2 h-3 w-3 rounded-full ${
										sortBy === "Oldest"
											? "bg-red-600 border-2 h-4 w-4 border-white"
											: "bg-gray-400"
									}`}
								/>
							</div>
						</div>
					</div>

					{/* horizontal line */}
					<div className="border-2 border-gray-400" />

					{/* Upload Bundle */}
					<div className="mt-10">
						<h3 className="font-bold ">Upload Bundle</h3>
						<div
							{...getRootProps()}
							className="border-2 p-4 border-gray-400 mx-auto rounded-2xl h-64 cursor-pointer flex items-center justify-center"
						>
							<input {...getInputProps()} />
							<div className="text-center">
								<p className="text-center text-gray-400">
									Drag and drop a bid or rebate bundle to be processed.
								</p>
								{isUploading && (
									<div className="mt-2 ">
										<p>Uploading bundle</p>
										<div className="relative h-3 rounded-full border-2 border-gray-500">
											<div
												className="bg-gray-400 h-2 rounded-full absolute top-0"
												style={{ width: `${uploadProgress}%` }}
											></div>
										</div>
									</div>
								)}
								{!isUploading && uploadedFileName && (
									<div className="mt-2 text-gray-600">
										<p className="text-left font-bold">
											Uploaded bundle:{" "}
											<div className="flex items-center justify-between ">
												<span className="font-semibold">
													{uploadedFileName}
												</span>
												<button
													onClick={handleRemoveFile}
													className="ml-2 text-red-500 hover:text-red-700"
												>
													<FaTrashAlt className="w-3" />
												</button>
											</div>
										</p>
									</div>
								)}
							</div>
						</div>
						{/* <div
							className="border-2 p-4 border-gray-400 mx-auto rounded-2xl h-64 cursor-pointer flex items-center justify-center"
							onDrop={handleFileDrop}
							onDragOver={(e) => e.preventDefault()}
						>
							<div className="text-center">
								<p className="text-center text-gray-400">
									Drag and drop a bid or rebate bundle to be processed.
								</p>
								{isUploading && (
									<div className="mt-2">
										<div className="w-full bg-gray-200 rounded-full">
											<div className="bg-blue-600 h-2 rounded-full w-1/2"></div>
										</div>
									</div>
								)}
							</div>
						</div> */}
					</div>
				</div>
			}
			rightContent={
				<div>
					<h2 className="text-xl font-semibold mb-4">Recent Transactions</h2>

					<div className="flex flex-wrap gap-4 max-h-screen scrollbar-container">
						{transactions.map((transaction) => (
							<TransactionCard key={transaction.id} transaction={transaction} />
						))}
					</div>
				</div>
			}
		/>
	);
};

export default Transactions;
