import React from "react";
import Notification from "./Notification";
import { CircleHelp } from "lucide-react";

const RightMenu = () => {
	return (
		<div className="flex items-center self-end">
			<CircleHelp size={18} />
			<Notification />
			<div className="relative">
				<div className="w-[30px] h-[30px] lg:w-[39px] lg:h-[39px] flex items-center justify-center rounded-full mr-[5px]">
					<img
						src="https://picsum.photos/100"
						alt="user"
						className="w-6 h-6 lg:w-8 lg:h-8 rounded-full object-cover"
					/>
				</div>
			</div>
		</div>
	);
};

export default RightMenu;
