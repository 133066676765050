import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import Step1 from "./components/onboarding/Step1";
import Step2 from "./components/onboarding/Step2";
import Step3 from "./components/onboarding/Step3";
import Step4 from "./components/onboarding/Step4";
import Step5 from "./components/onboarding/Step5";
import Step6 from "./components/onboarding/Step6";
import Login from "./components/auth/Login";
import ResetPassword from "./components/auth/ResetPassword";
import ResetPasswordForm from "./components/auth/ResetPasswordForm";
import ResetPasswordLink from "./components/auth/ResetPasswordLink";
import ResetPasswordComplete from "./components/auth/ResetPasswordComplete";
import DashboardLayout from "./layouts/dashboardLayout";
import MultiStep from "./components/onboarding/MultiStep";
import { RoutePaths } from "./routes";
import { isTokenValid } from "./util";
import ProtectedRoute from "./api/protectedRoute";
import Transactions from "./pages/dashboard/transactions";
import Assesments from "./pages/dashboard/assesments";
import Insights from "./pages/dashboard/insights";

function App() {
	return (
		<Router>
			<Routes>
				{/* Authentication and Reset Password */}
				<Route path="/" element={<Login />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/reset-password-form" element={<ResetPasswordForm />} />
				{/* <Route path="/reset-password-form" element={<ResetPasswordForm />} /> */}
				<Route path="/reset-password-link" element={<ResetPasswordLink />} />
				<Route
					path="/reset-password-complete"
					element={<ResetPasswordComplete />}
				/>

				{/* Onboarding Steps */}
				{/* <Route path="/onboarding/step1" element={<Step1 />} />
				<Route path="/onboarding/step2" element={<Step2 />} />
				<Route path="/onboarding/step3" element={<Step3 />} />
				<Route path="/onboarding/step4" element={<Step4 />} />
				<Route path="/onboarding/step5" element={<Step5 />} />
				<Route path="/onboarding/step6" element={<Step6 />} /> */}
				<Route path="/onboarding" element={<MultiStep />} />
				{/* Main Dashboard */}
				<Route
					element={
						<ProtectedRoute>
							<DashboardLayout />
						</ProtectedRoute>
					}
				>
					<Route path={RoutePaths.TRANSACTIONS} element={<Transactions />} />
					<Route
						path={RoutePaths.TRANSACTION_DETAILS}
						element={<Transactions />}
					/>
					<Route path={RoutePaths.ASSESMENTS} element={<Assesments />} />
					<Route path={RoutePaths.INSIGHTS} element={<Insights />} />
				</Route>
			</Routes>
		</Router>
	);
}

export default App;
