import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "../util";
import Cookies from "js-cookie";
import { RoutePaths } from "../routes";

const ProtectedRoute = ({ children }) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		const validateToken = async () => {
			const token = Cookies.get("accessToken");
			const valid = await isTokenValid(token);
			if (!valid) {
				navigate(RoutePaths.LOGIN);
			}
			setIsValid(valid);
			setIsLoading(false);
		};

		validateToken();
	}, []);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return isValid ? children : null;
};

export default ProtectedRoute;
