import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CiCircleQuestion } from "react-icons/ci";
import { saveBusinessInfo } from "../../store/slices";
import Tooltip from "../Tooltip";

import AuthImage from "../../assets/images/backgrounds/Step2.png";

const Step2 = ({ nextStep, prevStep }) => {
	const dispatch = useAppDispatch();
	const { personalInfo } = useAppSelector((state) => state.form);

	const formik = useFormik({
		initialValues: {
			companyName: "",
			website: "",
			industry: "",
			businessAddress: "",
			emailProvider: "O365",
			verticalEmail: "",
			dbERP: "",
		},
		validationSchema: yup.object({
			companyName: yup.string().required("Company name required!"),
			website: yup
				.string()
				.matches(
					/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,6})(\/.*)?$/,
					"Invalid URL"
				)
				.required("website url is required"),
			industry: yup.string().required("industry is required"),
			businessAddress: yup.string().required("Business address required!"),
			dbERP: yup.string().required("Database or ERP required!"),
			// emailConnection: yup.string().required("Required"),
		}),
		onSubmit: (values) => {
			dispatch(saveBusinessInfo(values)); // Dispatch the form values
			nextStep();
		},
	});

	return (
		<AuthLayout
			title={`Hello ${personalInfo.firstName} ${personalInfo.lastName}`} // Use name from personalInfo
			description="We need some basic information about your business to kick off your seamless automation."
			backgroundImage={AuthImage}
			backgroundText="Say hello to a simple onboarding."
		>
			{/* Form Section */}
			<form
				onSubmit={formik.handleSubmit}
				className="space-y-5 text-xs overflow-scroll-hidden overscroll-none"
			>
				{/* Legal Company Name */}
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Legal Company Name{" "}
							<Tooltip text="Enter the full legal name of your business.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.errors.companyName && (
							<div className="text-red-500 text-[10px]">
								{formik.errors.companyName}
							</div>
						)}
					</label>
					<input
						type="text"
						name="companyName"
						value={formik.values.companyName}
						// onChange={formik.handleChange}
						onChange={(e) => {
							const value = e.target.value.replace(/[0-9]/g, "");
							formik.setFieldValue("companyName", value);
						}}
						maxLength={20}
						onBlur={formik.handleBlur}
						placeholder="John Doe"
						className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg`}
					/>
				</div>

				{/* Website */}
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Website{" "}
							<Tooltip text="Enter your business website.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.errors.website && (
							<div className="text-red-500 text-[10px]">
								{formik.errors.website}
							</div>
						)}
					</label>
					<input
						type="text"
						name="website"
						value={formik.values.website}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						placeholder="acmeco.com"
						className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg`}
					/>
				</div>

				{/* Industry */}
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Industry{" "}
							<Tooltip text="Select the industry your business belongs to.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.errors.industry && (
							<div className="text-red-500 text-[10px]">
								{formik.errors.industry}
							</div>
						)}
					</label>
					<input
						type="text"
						name="industry"
						value={formik.values.industry}
						// onChange={formik.handleChange}
						onChange={(e) => {
							const value = e.target.value.replace(/[0-9]/g, "");
							formik.setFieldValue("industry", value);
						}}
						maxLength={20}
						onBlur={formik.handleBlur}
						placeholder="Food Distribution"
						className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg`}
					/>
				</div>

				{/* Business Address */}
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Business Address{" "}
							<Tooltip text="Provide the official address of your business.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.errors.businessAddress && (
							<div className="text-red-500 text-[10px]">
								{formik.errors.businessAddress}
							</div>
						)}
					</label>
					<input
						type="text"
						name="businessAddress"
						value={formik.values.businessAddress}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						placeholder="123 Business St"
						className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg`}
					/>
				</div>

				{/* Database/ERP */}
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Database/ERP{" "}
							<Tooltip text="Specify the database or ERP system your business uses.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.errors.dbERP && (
							<div className="text-red-500 text-[10px]">
								{formik.errors.dbERP}
							</div>
						)}
					</label>
					<input
						type="text"
						name="dbERP"
						value={formik.values.dbERP}
						// onChange={formik.handleChange}
						onChange={(e) => {
							const value = e.target.value.replace(/[0-9]/g, "");
							formik.setFieldValue("dbERP", value);
						}}
						maxLength={20}
						onBlur={formik.handleBlur}
						placeholder="SQL/NAV(Custom)"
						className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg`}
					/>
				</div>

				{/* Connect Incoming Email */}
				{/* <div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Connect vertical Email{" "}
							<Tooltip text="Choose your email provider to connect incoming mail.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
					</label>
					<div className="flex flex-row gap-8">
						<div className="w-full mt-2 px-3 py-2 bg-[#ffffff] text-center text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg">
							{formik.values.emailProvider}
						</div>
						<button className="w-full mt-2 px-3 py-2 bg-[#ffffff] text-center text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg">
							Google
						</button>
					</div>
				</div> */}
				<div className="flex w-full items-center gap-4">
					{/* Previous Button */}
					<button
						type="button"
						className="w-full px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
						onClick={prevStep}
					>
						Previous
					</button>
					{/* Next Button */}
					<button
						type="submit"
						className="w-full px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
					>
						Next
					</button>
				</div>
			</form>
		</AuthLayout>
	);
};

export default Step2;
