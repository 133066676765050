import React, { useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { RoutePaths } from "../../routes";

import AuthImage from "../../assets/images/backgrounds/Step6.png";
import { useNavigate } from "react-router-dom";

const Step6 = ({ apiComplete, progressMessage }) => {
	const [progress, setProgress] = React.useState(7);
	let navigate = useNavigate();

	const progressColor =
		progressMessage === "Tenant setup failed!"
			? "#D61B1B"
			: progress < 100
			? "#e0c226"
			: "#1BD623";

	const progressText =
		progress === 100
			? progressMessage
			: `${progress}% ${progressMessage || "Tenant setup in progress..."}`;
	console.log(progressMessage, "Message");

	useEffect(() => {
		let interval;

		if (progressMessage === "Tenant setup failed!") {
			setProgress(99);
			clearInterval(interval);
			return;
		}

		// if (progressMessage === "Tenant setup in progress...") {
		// 	setProgress(20);
		// 	clearInterval(interval);
		// 	return;
		// }

		if (apiComplete) {
			setProgress(100);
		} else {
			interval = setInterval(() => {
				setProgress((prev) => (prev < 99 ? prev + 1 : 99));
			}, 100);
		}

		return () => clearInterval(interval);
	}, [apiComplete, progressMessage]);

	const handleProceed = () => {
		navigate(RoutePaths.TRANSACTIONS);
	};

	return (
		<AuthLayout
			title="Tenant creation"
			description="Setting up your workspace now. This won’t take long. PAIBI is preparing everything you need to succeed."
			backgroundImage={AuthImage}
			backgroundText="Spinning up your custom automated environment."
		>
			<div className="w-full text-gray-600 pt-16 pb-12">
				<div className="mt-8 space-y-5 ">
					<p className="font-bold">Creation Progress</p>
					<div className="w-full h-[30px] relative bg-gray-300 rounded-lg overflow-hidden ">
						<div
							className="relative h-full py-2 text-white font-medium text-center flex items-center justify-center rounded-lg"
							style={{
								width: `${progress}%`,
								backgroundColor: progressColor,
							}}
						>
							{" "}
						</div>
						<p className="absolute top-1/2 -translate-y-1/2  text-center w-full text-gray-200 ">
							{progressText}{" "}
						</p>
					</div>
					{/* <button className="w-full px-4 py-2 text-white font-medium bg-[#B0B5C9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150">
						Go to Tutorial
					</button> */}
					<button
						className={`w-full px-4 py-2 text-white font-medium ${
							progress === 100
								? "bg-[#1BD623] hover:bg-indigo-500 active:bg-indigo-600"
								: "bg-[#C2C2C2] cursor-not-allowed"
						} rounded-lg duration-150`}
						disabled={progress !== 100}
						onClick={handleProceed}
					>
						Go to Dashboard
					</button>
				</div>
			</div>
		</AuthLayout>
	);
};

export default Step6;
