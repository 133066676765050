// axios.jsx

import axios from 'axios';
import { API_URL } from "../environmentVariable";
import Cookies from "js-cookie";

const baseURL = API_URL || "http://172.232.24.149:3000";

const apiResource = () => {
	const api = axios.create({
		baseURL,
		headers: {
			"Content-Type": "application/json",
		},
		withCredentials: false,
	});

	api.interceptors.request.use(
		(config) => {
			// Skip token check if this is a login request or onboarding
			const path = window.location.href.includes("onboarding");
			if (config.url === "/v1/auth/login" || path) {
				return config;
			}

			// For all other requests, check for accessToken
			const token = Cookies.get("accessToken");
			if (!token) {
				window.location.href = "/";
			} else {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	return api;
};


export const appAxios = apiResource();


