import { Bell } from "lucide-react";
import { Link } from "react-router-dom";

const Notification = () => {
	return (
		<Link to="#" className="relative mr-[15px]">
			<div className="w-[7px] h-[7px] bg-red-error rounded-full absolute right-[5px] top-0" />
			<Bell size={18} />
		</Link>
	);
};

export default Notification;
