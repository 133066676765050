import React, { useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "../../store/hooks";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { CiBellOn, CiCircleQuestion } from "react-icons/ci";
import { savePersonalInfo } from "../../store/slices";
import Tooltip from "../Tooltip";
import { appAxios } from "../../api/axios";
import AuthImage from "../../assets/images/backgrounds/Step1.png";
import { Link } from "react-router-dom";

const Step1 = ({ nextStep }) => {
	const dispatch = useAppDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			userType: "teamMember",
			role: "primary",
		},
		validationSchema: yup.object({
			firstName: yup
				.string()
				.required("First name required!")
				.max(20, "First name must be more than 20 characters"),
			lastName: yup
				.string()
				.required("Last name required!")
				.max(20, "Last name must be more than 20 characters"),
			email: yup
				.string()
				.test("is-valid-email", "Invalid email address!", (value) => {
					if (!value) return false;
					const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
					return emailPattern.test(value);
				})
				.required("Email address required!"),

			password: yup
				.string()
				.min(8, "Minimum of 8 characters")
				.matches(/[!@#$%^&*(),.?":{}|<>]/, "One special character")
				.matches(/[0-9]/, "One number")
				.matches(/[A-Z]/, "One uppercase character")
				.matches(/[a-z]/, "One lowercase character")
				.required("Password is required"),
		}),
		onSubmit: (values) => {
			dispatch(savePersonalInfo(values));
			checkEmailExist(values);
		},
	});

	const handlePasswordVisibilityToggle = () => {
		setShowPassword(!showPassword);
	};

	//Check if an email already exist. If an email exist, display an error message
	const checkEmailExist = async (values) => {
		try {
			const response = await appAxios.get(
				`/v1/onboarding/check-email?email=${values.email}`
			);
			if (
				response.data.statusCode === 200 &&
				response.data.data.exists === false
			) {
				nextStep();
			} else if (
				response.data.statusCode === 200 &&
				response.data.data.exists === true
			) {
				setErrorMessage(response?.data?.message);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<AuthLayout
			title="Create your account"
			description="Tell us a bit about yourself so we can tailor your PAIBI experience. Your details help us serve you better."
			backgroundImage={AuthImage}
			backgroundText="Say goodbye to stacks of paper."
			// buzzSales={
			// 	<button
			// 		type="button"
			// 		className="text-gray-900 mt-5 flex flex-row items-center gap-2 bg-white border border-slate-500 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2"
			// 	>
			// 		<CiBellOn /> Buzz the sales team
			// 	</button>
			// }
			additionalInfo={
				<Link to="/">
					Already have an Account?
					<span className="text-[#9cabd7] hover:underline "> Sign in</span>
				</Link>
			}
		>
			{/* Form Fields */}
			<form onSubmit={formik.handleSubmit} className="space-y-5 text-xs">
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2 ">
							First Name{" "}
							<Tooltip text="Enter your first name.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.firstName && formik.errors.firstName ? (
							<div className="text-red-500 text-[10px]">
								{formik.errors.firstName}
							</div>
						) : null}
					</label>
					<input
						type="text"
						name="firstName"
						placeholder="John"
						value={formik.values.firstName}
						onChange={(e) => {
							const value = e.target.value.replace(/[0-9]/g, "");
							formik.setFieldValue("firstName", value);
						}}
						maxLength={20}
						onBlur={formik.handleBlur}
						errors={formik.errors.firstName}
						className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg 
						${formik.touched.firstName && formik.errors.firstName ? "border-red-500" : ""}
							`}
					/>
				</div>

				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Last Name{" "}
							<Tooltip text="Enter your last name.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.lastName && formik.errors.lastName ? (
							<div className="text-red-500 text-[10px]">
								{formik.errors.lastName}
							</div>
						) : null}
					</label>
					<input
						type="text"
						name="lastName"
						placeholder="Doe"
						value={formik.values.lastName}
						maxLength={20}
						onChange={(e) => {
							const value = e.target.value.replace(/[0-9]/g, "");
							formik.setFieldValue("lastName", value);
						}}
						onBlur={formik.handleBlur}
						className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg ${
							formik.touched.lastName && formik.errors.lastName
								? "border-red-500"
								: ""
						}`}
					/>
				</div>

				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Email{" "}
							<Tooltip text="Enter a valid email address for account creation.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.email && formik.errors.email ? (
							<div className="text-red-500 text-[10px]">
								{formik.errors.email}
							</div>
						) : null}
					</label>
					<input
						type="text"
						name="email"
						placeholder="john@acmeco.com"
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg ${
							(formik.touched.email && formik.errors.email) ||
							errorMessage === "Email is already in use"
								? "border-red-500"
								: ""
						}`}
					/>
					{/* Error message deplayed if email exist */}
					{errorMessage && (
						<div className=" space-y-2 flex gap-2 items-center justify-end my-[0px] ">
							<p className="text-[#D61B1B] text-[10px] my-[0px] text-end">
								{errorMessage}
							</p>
						</div>
					)}
				</div>

				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Password{" "}
							<Tooltip text="Create a strong password with a minimum of 8 characters.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.password && formik.errors.password ? (
							<div className="text-red-500 text-[10px]">
								{formik.errors.password}
							</div>
						) : null}
					</label>
					<div className="relative">
						<input
							type={showPassword ? "text" : "password"}
							name="password"
							placeholder="**************"
							value={formik.values.password}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg ${
								formik.touched.password && formik.errors.password
									? "border-red-500"
									: ""
							}`}
						/>
						<span
							onClick={handlePasswordVisibilityToggle}
							className="absolute mt-1 right-2 top-1/2 -translate-y-1/2 cursor-pointer"
						>
							{showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
						</span>
					</div>
				</div>

				<button
					type="submit"
					className="w-full px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
				>
					Get Started
				</button>
			</form>
		</AuthLayout>
	);
};

export default Step1;
