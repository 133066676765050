import React, { useState, useRef, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import { FiShare2, FiDownload, FiExternalLink } from "react-icons/fi";

const DropdownMenu = ({ onViewDetails }) => {
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef();

	const toggleMenu = () => setIsOpen((prev) => !prev);

	// Close dropdown if clicked outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	return (
		<div className="relative" ref={menuRef}>
			<button
				onClick={toggleMenu}
				className="p-2 text-gray-600 hover:text-gray-800"
			>
				<BsThreeDots size={20} />
			</button>
			{isOpen && (
				<div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10">
					<ul className="py-2">
						<li
							className="px-4 py-2 hover:bg-gray-100 flex items-center cursor-pointer"
							onClick={() => alert("Share transaction")}
						>
							<FiShare2 className="mr-2" /> Share Transaction
						</li>
						<li
							className="px-4 py-2 hover:bg-gray-100 flex items-center cursor-pointer"
							onClick={() => alert("Download bundle")}
						>
							<FiDownload className="mr-2" /> Download Bundle
						</li>
						<li
							className="px-4 py-2 hover:bg-gray-100 flex items-center cursor-pointer"
							onClick={onViewDetails}
						>
							<FiExternalLink className="mr-2" /> Export Assesment
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default DropdownMenu;
