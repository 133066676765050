// Step5.jsx

import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { saveAgreementInfo } from "../../store/slices";
import AuthImage from "../../assets/images/backgrounds/Step5.png";
import { appAxios } from "../../api/axios";

const Step5 = ({ nextStep, prevStep, setApiComplete, setProgressMessage }) => {
	const dispatch = useAppDispatch();
	const formData = useAppSelector((state) => state.form);

	let navigate = useNavigate();
	// Formik setup
	const formik = useFormik({
		initialValues: {
			agreed: false,
		},
		validationSchema: yup.object({
			agreed: yup
				.boolean()
				.oneOf([true], "You must accept the terms and conditions!"),
		}),
		onSubmit: async (values) => {
			nextStep();

			try {
				const response = await appAxios.post(
					"/v1/onboarding/complete",
					formData
				);
				if (
					response.data.statusCode === 201 &&
					response.data.success === true
				) {
					setApiComplete(true);
					// setProgressMessage(response?.data?.message);
					// navigate("/dashboard");
					getTanantId(response?.data?.data?.tenantId);
				} else if (
					response.data.status !== 201 ||
					response.data.status !== 400 ||
					response.data.status !== 500
				) {
					setApiComplete(false);
					setProgressMessage("Tenant setup failed!");
				} else {
					setApiComplete(false);
					setProgressMessage(response?.data?.message);
				}
			} catch (error) {
				console.error("Form submission error:", error);
				setProgressMessage(error?.response?.data?.message);
				setApiComplete(false);
				// setProgressMessage(error?.response?.data?.message);
			}
		},
	});

	const getTanantId = async (id) => {
		try {
			const response = await appAxios.get(`/v1/tenants?id=${id}`, formData);
			if (
				response?.data?.statusCode === 201 &&
				response?.data?.success === true
			) {
				setProgressMessage("Tenant setup successful!");
				// navigate("/dashboard");
			} else {
				setProgressMessage("Tenant setup failed!");
			}
		} catch (error) {
			console.error("Form submission error:", error?.response?.data?.message);
			setProgressMessage(error?.response?.data?.message);
		}
	};

	const handleCheckboxChange = (e) => {
		formik.handleChange(e);
		dispatch(saveAgreementInfo({ agreed: e.target.checked })); // Dispatch Redux action
	};

	return (
		<AuthLayout
			title="Agree to terms"
			description="Let’s make it official. Review the terms, and with a quick click, you're ready to start optimizing."
			backgroundImage={AuthImage}
			backgroundText="Keep your data safe... and ours too."
		>
			<form onSubmit={formik.handleSubmit}>
				<div className="border border-gray-400 p-2 h-[250px] overflow-scroll text-xs">
					<p className="space-y-3">
						<div>
							<p>Acceptance of Terms</p>
							<p>
								By using our services, you agree to comply with and be bound by
								these terms and conditions.
							</p>
						</div>{" "}
						<div>
							<p>User Responsibilities</p>
							<p>
								You are responsible for maintaining the confidentiality of your
								account and password and for restricting access to your
								computer. You agree to accept responsibility for all activities
								that occur under your account or password.
							</p>
						</div>{" "}
						<div>
							<p>Use of Service</p>
							<p>
								You agree to use our services only for lawful purposes and in a
								manner that does not infringe the rights of or restrict the use
								and enjoyment of our services by any third party.
							</p>
						</div>{" "}
						<div>
							<p>Data Privacy</p>
							<p>
								We take your privacy seriously. Please review our Privacy Policy
								to understand how we collect, use, and safeguard your
								information.
							</p>
						</div>{" "}
						<div>
							<p>Limitation of Liability</p>
							<p>
								We are not liable for any damages that may result from your use
								of our services, including but not limited to direct, indirect,
								incidental, punitive, and consequential damages.
							</p>
						</div>{" "}
						<div>
							<p>Modifications to Terms</p>
							<p>
								We reserve the right to change these terms at any time. Any
								updates will be posted on this page, and it is your
								responsibility to review these terms regularly. Termination We
								reserve the right to terminate or suspend your account at our
								sole discretion, without notice, if you breach these terms.
							</p>
						</div>{" "}
					</p>
				</div>

				<p className="font-bold pt-2 text-xs cursor-pointer pb-4">
					I Agree to the terms and conditions
				</p>
				<div className="flex items-center pb-4 text-xs">
					<input
						id="link-checkbox"
						type="checkbox"
						name="agreed"
						checked={formik.values.agreed}
						onChange={handleCheckboxChange}
						className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
					/>
					<label
						htmlFor="link-checkbox"
						className="ms-2 text-xs font-medium text-[#1b1b1b]]"
					>
						I have read and agree to the terms and conditions.
					</label>
				</div>
				{formik.touched.agreed && formik.errors.agreed && (
					<p className="text-red-500 text-[10px]">{formik.errors.agreed}</p>
				)}

				<div className="flex w-full items-center gap-4">
					{/* Previous Button */}
					<button
						type="button"
						className="w-full px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
						onClick={prevStep}
					>
						Previous
					</button>
					{/* Create Account Button */}
					<button
						type="submit"
						disabled={!formik.values.agreed}
						className={`w-full px-4 py-2 text-white font-medium rounded-lg duration-150 ${
							formik.values.agreed
								? "bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600"
								: "bg-gray-400 cursor-not-allowed"
						}`}
					>
						Create Account
					</button>
				</div>
			</form>
		</AuthLayout>
	);
};

export default Step5;
