import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CiCircleQuestion } from "react-icons/ci";
import { savePaymentInfo } from "../../store/slices";
import AuthImage from "../../assets/images/backgrounds/Step4.jpeg";
import Tooltip from "../Tooltip";

const Step4 = ({ nextStep, prevStep }) => {
	const dispatch = useAppDispatch();
	const { personalInfo } = useAppSelector((state) => state.form);

	// Formik form handling
	const formik = useFormik({
		initialValues: {
			customerEmail: "",
			paymentDetails: {
				cardName: "",
				cardNumber: "",
				expiryDate: "",
				cvv: "",
				billingAddress: "",
				postalCode: "12345",
				city: "Anytown",
				state: "AnyState",
				country: "US",
			},
		},
		validationSchema: yup.object({
			customerEmail: yup
				.string()
				.test("is-valid-email", "Invalid email address!", (value) => {
					// Custom email validation logic
					if (!value) return false;
					const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
					return emailPattern.test(value);
				})
				.required("Billing Email required!"),
			paymentDetails: yup.object({
				cardName: yup.string().required("Name on Card required!"),
				// Validation for different card types
				cardNumber: yup
					.string()
					.required("Card Number required!")
					.test("is-valid-card", "Card number is not valid!", (value) => {
						if (!value) return false;
						const sanitizedValue = value.replace(/\s/g, "");

						const visaPattern = /^4(\d{12}|\d{15})$/;
						const masterCardPattern =
							/^(5[1-5]\d{14}|2(2[2-9]\d{2}|[3-6]\d{3}|7[0-1]\d{2}|720)\d{12})$/;
						const amexPattern = /^3[47]\d{13}$/;
						const discoverPattern =
							/^(6011\d{12}|65\d{14}|64[4-9]\d{13}|622(12[6-9]|1[3-9]\d|2[0-5]\d|92[0-5])\d{10})$/;
						const jcbPattern = /^(352[8-9]\d{12}|35[3-8]\d{13})$/;
						const dinersClubPattern = /^3(0[0-5]\d{11}|[68]\d{12})$/;

						return (
							visaPattern.test(sanitizedValue) ||
							masterCardPattern.test(sanitizedValue) ||
							amexPattern.test(sanitizedValue) ||
							discoverPattern.test(sanitizedValue) ||
							jcbPattern.test(sanitizedValue) ||
							dinersClubPattern.test(sanitizedValue)
						);
					}),
				// expiryDate: yup
				// 	.string()
				// 	.matches(/^\d{2}\/\d{2}$/, "Expiration date is not valid")
				// 	.required("Expiration Date is required"),
				expiryDate: yup
					.string()
					.required("Expiration Date required!")
					.matches(/^\d{2}\/\d{2}$/, "Expiration date is not valid!")
					.test("is-valid-expiry", "Expiration date is not valid!", (value) => {
						if (!value) return false;

						const [month, year] = value.split("/").map(Number);
						const currentYear = new Date().getFullYear() % 100; // Get last two digits of the year
						const currentMonth = new Date().getMonth() + 1; // Months are zero-based in JS

						// Check if month is between 01 and 12
						if (month < 1 || month > 12) return false;

						// Check if year is between the current year and 20 years in the future
						if (year < currentYear || year > currentYear + 20) return false;

						// Check if the card is expired
						if (year === currentYear && month < currentMonth) return false;

						return true;
					}),
				cvv: yup
					.string()
					.matches(/^\d{3,4}$/, "CVC/CVV not valid!")
					.required("CVC/CVV required!"),
				billingAddress: yup.string().required("Billing Address required!"),
			}),
		}),
		onSubmit: (values) => {
			dispatch(savePaymentInfo(values));
			nextStep();
		},
	});

	const handleCardNumberChange = (e) => {
		let value = e.target.value.replace(/\D/g, "");
		if (value.length > 16) return;
		value = value.replace(/(.{4})/g, "$1 ").trim();
		formik.setFieldValue("paymentDetails.cardNumber", value);
	};

	// Custom input handler for expiry date
	const handleExpiryDateChange = (e) => {
		let value = e.target.value.replace(/\D/g, "");
		if (value.length > 4) return;
		if (value.length > 2) {
			value = `${value.slice(0, 2)}/${value.slice(2)}`;
		}
		formik.setFieldValue("paymentDetails.expiryDate", value);
	};

	// Custom input handler for CVV
	const handleCvvChange = (e) => {
		let value = e.target.value.replace(/\D/g, "");
		if (value.length > 4) return;
		formik.setFieldValue("paymentDetails.cvv", value);
	};

	return (
		<AuthLayout
			title="Add payment method"
			description="Almost there! Securely enter your payment details to activate your PAIBI subscription. Your card will not be charged until the 7 day free trial is over."
			backgroundImage={AuthImage}
			backgroundText="Get ready for your automated AI experience"
		>
			<form
				onSubmit={formik.handleSubmit}
				className="space-y-5 text-xs overflow-hidden overscroll-none"
			>
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Name on Card{" "}
							<Tooltip text="Enter the name exactly as it appears on the card.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.paymentDetails?.cardName &&
						formik.errors.paymentDetails?.cardName ? (
							<p className="text-red-500 text-[10px]">
								{formik.errors.paymentDetails?.cardName}
							</p>
						) : null}
					</label>
					<input
						type="text"
						name="paymentDetails.cardName"
						placeholder="John Doe"
						value={formik.values.paymentDetails?.cardName}
						// onChange={formik.handleChange}
						onChange={(e) => {
							const value = e.target.value.replace(/[0-9]/g, "");
							formik.setFieldValue("paymentDetails.cardName", value);
						}}
						maxLength={20}
						onBlur={formik.handleBlur}
						className="w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
					/>
				</div>

				{/* Other Input Fields */}
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Card Number{" "}
							<Tooltip text="Enter your 16-digit credit or debit card number.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.paymentDetails?.cardNumber &&
						formik.errors.paymentDetails?.cardNumber ? (
							<p className="text-red-500 text-[10px]">
								{formik.errors.paymentDetails?.cardNumber}
							</p>
						) : null}
					</label>
					<input
						type="text"
						name="paymentDetails.cardNumber"
						placeholder="1234 1234 1234 1234"
						value={formik.values.paymentDetails?.cardNumber}
						onChange={handleCardNumberChange}
						onBlur={formik.handleBlur}
						className="w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
					/>
				</div>

				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Expiration Date{" "}
							<Tooltip text="Enter the card's expiration date in MM/YY format.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.paymentDetails?.expiryDate &&
						formik.errors.paymentDetails?.expiryDate ? (
							<p className="text-red-500 text-[10px]">
								{formik.errors.paymentDetails?.expiryDate}
							</p>
						) : null}
					</label>
					<input
						type="text"
						name="paymentDetails.expiryDate"
						placeholder="MM/YY"
						value={formik.values.paymentDetails?.expiryDate}
						onChange={handleExpiryDateChange}
						onBlur={formik.handleBlur}
						className="w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
					/>
				</div>

				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							CVC/CVV{" "}
							<Tooltip text="Enter the 3- or 4-digit code on the back of your card.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.paymentDetails?.cvv &&
						formik.errors.paymentDetails?.cvv ? (
							<p className="text-red-500 text-[10px]">
								{formik.errors.paymentDetails?.cvv}
							</p>
						) : null}
					</label>
					<input
						type="text"
						name="paymentDetails.cvv"
						placeholder="123"
						value={formik.values.paymentDetails?.cvv}
						onChange={handleCvvChange}
						onBlur={formik.handleBlur}
						className="w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
					/>
				</div>

				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Billing Address{" "}
							<Tooltip text="Enter the billing address associated with this card.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.paymentDetails?.billingAddress &&
						formik.errors.paymentDetails?.billingAddress ? (
							<p className="text-red-500 text-[10px]">
								{formik.errors.paymentDetails?.billingAddress}
							</p>
						) : null}
					</label>
					<input
						type="text"
						name="paymentDetails.billingAddress"
						placeholder="123 Main Street, MD."
						value={formik.values.paymentDetails?.billingAddress}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className="w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
					/>
				</div>
				<div>
					<label className="font-medium flex flex-row items-center justify-between gap-2">
						<p className="flex flex-row items-center gap-2">
							Billing Email{" "}
							<Tooltip text="Enter the email where billing notifications will be sent.">
								<CiCircleQuestion className="cursor-pointer" />
							</Tooltip>
						</p>
						{formik.touched.customerEmail && formik.errors.customerEmail ? (
							<div className="text-red-500 text-[10px]">
								{formik.errors.customerEmail}
							</div>
						) : null}
					</label>
					<input
						type="email"
						name="customerEmail"
						placeholder="john@acmeco.com"
						value={formik.values.customerEmail}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						className={`w-full mt-2 px-3 py-2 bg-[#ffffff] text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg ${
							formik.touched.customerEmail && formik.errors.customerEmail
								? "border-red-500"
								: ""
						}`}
					/>
				</div>

				<div className="flex w-full items-center gap-4">
					{/* Previous Button */}
					<button
						type="button"
						className="w-full px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
						onClick={prevStep}
					>
						Previous
					</button>
					{/* Next Button */}
					<button
						type="submit"
						className="w-full px-4 py-2 text-white font-medium bg-[#b0b5c9] hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
					>
						Next
					</button>
				</div>
			</form>
		</AuthLayout>
	);
};

export default Step4;
