import React from "react";
import { BsThreeDots } from "react-icons/bs";
import DropdownMenu from "./DropdownMenu";
import { useNavigate } from "react-router-dom";

const TransactionCard = ({
	title,
	date,
	time,
	description,
	id,
	image,
	status,
	transaction,
}) => {
	const navigate = useNavigate();

	const handleViewDetails = () => {
		navigate(`/transactions/${transaction.id}`);
	};

	return (
		<div className="bg-white rounded-3xl border p-4 shadow-sm flex flex-col gap-3 w-full md:w-[48%] lg:w-[32%]">
			{/* Card Header */}
			<div className="flex justify-between items-center">
				<h3 className="text-lg font-semibold text-gray-800">
					{transaction?.title}
				</h3>
				<DropdownMenu onViewDetails={handleViewDetails} />
				{/* <BsThreeDots className="text-gray-500 cursor-pointer" /> */}
			</div>

			{/* Date and Time */}
			<p className="text-gray-500 text-sm">{`${transaction?.date} | ${transaction?.time}`}</p>

			{/* Description and ID */}
			<div className="flex justify-between items-center text-gray-600 text-sm">
				<span>{transaction?.description}</span>
				<span>ID: {transaction?.id}</span>
			</div>

			{/* Image */}
			<div className="flex justify-center">
				<img
					src={transaction?.image}
					alt="Transaction"
					className="h-40 w-full object-cover "
				/>
			</div>

			{/* Status */}
			<p className={`font-medium`}>{transaction?.status} Transactions</p>

			{/* View Details Button */}
			<button
				onClick={handleViewDetails}
				className="mt-2 text-sm px-4 py-2 border-2 rounded-xl hover:bg-gray-400 transition"
			>
				View Details
			</button>
		</div>
	);
};

export default TransactionCard;
