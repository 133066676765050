import React from "react";

const MainLayout = ({ leftContent, rightContent }) => {
	return (
		<div className="flex flex-col lg:flex-row gap-6 p-4 min-h-screen ">
			{/* Left Column */}
			<div className="lg:w-4/12 w-full bg-gray-200 rounded-3xl border-gray-400 border-2 p-4 shadow-md">
				{leftContent}
			</div>

			{/* Right Column */}
			{/* <div className="h-100 overflow-auto">
			</div> */}
			<div className="lg:w-11/12 w-full bg-white min-h-screen overflow-auto rounded-3xl border-gray-400 border-2 p-4 shadow-md">
				{rightContent}
			</div>
		</div>
	);
};

export default MainLayout;
