import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const index = () => {
	return (
		<div className="max-h-screen h-full">
			<div className="flex flex-row flex-nowrap">
				<main className="w-full bg-BrandBg3 overflow-auto">
					<Navbar />
					<div className="px-[15px] py-[18px] min-h-screen mb-6">
						<Outlet />
					</div>
				</main>
			</div>
		</div>
	);
};

export default index;
