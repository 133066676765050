import { ReactNode } from "react";
import { RoutePaths } from "../../routes";

export const MainLinks = [
	{
		label: "Transactions",
		href: RoutePaths.TRANSACTIONS,
	},
	{
		label: "Assesments",
		href: RoutePaths.ASSESMENTS,
	},
	{
		label: "Insights",
		href: RoutePaths.INSIGHTS,
	},
];
