import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../components/dashboard/Logo";
import RightMenu from "../../components/dashboard/RightMenu";
import { MainLinks } from "./navLinks";

const Navbar = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<nav className="bg-white px-6 flex items-center py-4 z-10 sticky top-0">
			<div className="flex flex-row items-center py-3 justify-between w-full">
				{/* Left: Logo */}
				<Link to="/" className="cursor-pointer w-80">
					<Logo />
				</Link>

				{/* Center: Menu Links for Desktop */}
				<div className="hidden lg:flex space-x-4 bg-gray-400 p-1 rounded-full">
					{MainLinks.map(({ label, href }) => (
						<NavLink
							key={href}
							to={href}
							className={({ isActive }) =>
								`px-4 py-2 rounded-full font-medium ${
									isActive ? "bg-white" : "hover:bg-white hover:text-black"
								}`
							}
						>
							{label}
						</NavLink>
					))}
				</div>

				{/* Right: Menu and Icons */}
				<div className="lg:flex hidden">
					<RightMenu />
				</div>

				{/* Mobile Menu Button */}
				<button
					onClick={toggleMenu}
					className="lg:hidden p-2 text-gray-700 focus:outline-none"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						className="w-6 h-6"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M4 6h16M4 12h16M4 18h16"
						/>
					</svg>
				</button>
			</div>

			{/* Mobile Menu Links */}
			{isMenuOpen && (
				<div className="lg:hidden absolute top-16 left-0 w-full bg-white shadow-lg z-20">
					<div className="flex flex-col items-center py-4">
						{MainLinks.map(({ label, href }) => (
							<NavLink
								key={href}
								to={href}
								className="text-gray-700 py-2 w-full text-center hover:text-blue-600"
								onClick={() => setIsMenuOpen(false)}
							>
								{label}
							</NavLink>
						))}
					</div>
				</div>
			)}
		</nav>
	);
};

export default Navbar;
